import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Winter Term 2025",
  "date": "2025-01-24",
  "author": "Andy B",
  "featuredImage": "Designer-73.jpeg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sorry this is a little late, but here are the dates for the current term:`}</p>
    <ul>
      <li parentName="ul">{`11th January 2025`}</li>
      <li parentName="ul">{`25th January`}</li>
      <li parentName="ul">{`8th February`}</li>
      <li parentName="ul">{`22nd February`}</li>
      <li parentName="ul">{`8th March`}</li>
      <li parentName="ul">{`22nd March`}</li>
      <li parentName="ul">{`5th April`}</li>
    </ul>
    <p>{`Spaces for the sessions can be booked now, via the `}<a parentName="p" {...{
        "href": "https://codeclub.org/en/clubs/b05ca2dc-65c8-4501-bf67-869acc1773c9"
      }}>{`Code Club website`}</a>{`.`}</p>
    <p>{`See you soon for more coding and lego fun!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      